import "./App.css";
import { io } from "socket.io-client";
import { Device } from "mediasoup-client";
import React from "react";

const socket = io("https://163.172.134.184:3000/mediasoup");

let device;
let rtpCapabilities;
let consumerTransport;
let consumer;


function App() {
  const remoteVideo = React.useRef(null);
  const [elcore, setElcore] = React.useState(0);

  const goConnect = () => {
    getRtpCapabilities();
  };

   const getRtpCapabilities = () => {
    socket.emit("createRoom", (data) => {
      rtpCapabilities = data.rtpCapabilities;
      createDevice();
    });
  };

  const createDevice = async () => {
    try {
      device = new Device();

      await device.load({
        routerRtpCapabilities: rtpCapabilities,
      });
      goCreateTransport();
    } catch (error) {
      console.log(error);
      if (error.name === "UnsupportedError")
        console.warn("browser not supported");
    }
  };


  const goCreateTransport = () => {
    createRecvTransport();
  };

  const asistemtes = () => {
    socket.emit('totalAsistentes');
  }

  const connectRecvTransport = async () => {
    console.log('....',device.rtpCapabilities);
    socket.emit(
      "consume",
      {
        rtpCapabilities: device.rtpCapabilities,
      },
      async ({ params }) => {
        if (params.error) {
          console.log("Cannot Consume ");
          return;
        }

        console.log(params);

        consumer = await consumerTransport.consume({
          id: params.id,
          producerId: params.producerId,
          kind: params.kind,
          rtpParameters: params.rtpParameters,
        });

        let consumerAudio = await consumerTransport.consume({
          id: params.idAudio,
          producerId: params.producerIdAudio,
          kind: 'audio',
          rtpParameters: params.rtpParametersAudio,
        });

        const { track } = consumer;
        const trackAudio = consumerAudio.track;


        // remoteVideo.current.srcObject = new MediaStream([track]);
        remoteVideo.current.srcObject = new MediaStream([track, trackAudio]);
        // remoteAudio.current.play()
        // .then(()=>{})
        // .catch((e) => {
        //   console.log('Error iniciando el audio => ',e);
        // });

        socket.emit("consumer-resume");
      }
    );

    socket.emit(
      "consume-audiox",
      {
        rtpCapabilities: device.rtpCapabilities,
      },
      async ({ params }) => {
        if (params.error) {
          console.log("Cannot Consume");
          return;
        }

        console.log(params);

        consumer = await consumerTransport.consume({
          id: params.id,
          producerId: params.producerId,
          kind: 'audio',
          rtpParameters: params.rtpParameters,
        });

        const { track } = consumer;

        console.log('****',track);

        // remoteVideo.addTrack(new MediaStream([track]));

        // socket.emit("consumer-resume");
      }
    );

  };

  const createRecvTransport = async () => {
    socket.emit(
      "createWebRtcTransport",
      { sender: false, core: elcore },
      ({ params }) => {
        if (params.error) {
          console.log(params.error);
          return;
        }
        consumerTransport = device.createRecvTransport(params);
        consumerTransport.on(
          "connect",
          async ({ dtlsParameters }, callback, errback) => {
            try {
              await socket.emit("transport-recv-connect", {
                dtlsParameters,
              });

              callback();
            } catch (error) {
              errback(error);
            }
          }
        );

        connectRecvTransport();
      }
    );
  };

  React.useEffect(() => {
    console.log("Todo bien..");
    socket.on(
      "connection-success",
      ({ socketId, existsProducer, cantidad }) => {
        console.log(socketId, existsProducer);
      }
    );

    socket.on('asistentesT', (t) => {
      console.log(t);
    });

    goConnect();
  }, [goConnect]);

  return (
    <div
      className="App"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <video
        controls
        style={{ width: 1080, height: 720, marginBottom: 20 }}
        ref={remoteVideo}
        id="localVideo"
        autoPlay
      ></video>
      <input
        placeholder="core"
        onChange={(e) => {
          setElcore(e.currentTarget.value);
        }}
      />
      <button onClick={goConnect}>Recibir Transmisión</button>
      <button onClick={asistemtes}>Asistentes</button>
    </div>
  );
}

export default App;
